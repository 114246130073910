import { IonCol } from '@ionic/react';
import { ComponentProps, PropsWithChildren } from 'react';

// Styles
import styles from './SimpleColumn.module.css';

// Utils
import { mergeClassNames } from '../../utilities/mergeClassNames';

interface SimpleColumnProps extends ComponentProps<typeof IonCol> {
  type?: 'key' | 'value';
}

export const SimpleColumn = ({ children, type = 'value' }: PropsWithChildren<SimpleColumnProps>) => {
  return <IonCol className={mergeClassNames(styles.column, styles[type])}>{children}</IonCol>;
};
